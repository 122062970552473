import { Header } from '@/utils/datatable/datatableInterface';
import DatatableIntetrface from '@/utils/datatable/datatableInterface'
import Datatable  from '@/utils/datatable/datatable'
const headers:Header[] = [
  {value:'Id', text: 'Id' , isPrice : false , isTotal:false , total:0},
  {value:'Name', text: 'Name' , isPrice : false , isTotal:false , total:0},
  {value:'NameAr', text: 'NameAr' , isPrice : false , isTotal:false , total:0},
  {value:'Actions', text: 'Actions' , isPrice : false , isTotal:false , total:0},
]




const url = "classifications"

const params:DatatableIntetrface = {
    title : "classifications",
    headers ,
    description:"classifications_desc",
    searchable:true,
    url,
    hasFooter:false,
    hasEdit:true,
    hasCreate:true,
    hasDelete:true
}
const datatable = new Datatable(params)
export default datatable